import { TrainingMeta } from '../types/trainingMeta';
import { parseEthnicity } from './ethnicity';
import { parseBodyType } from './bodyType';
import { getGenderDescriptor, getHairLengthDescription } from './gender';
import { getAgeDescription } from './age';
import { getGlassesDescription } from './glasses';

export const getTrigger = (trainingMeta: TrainingMeta, {
  enabledAge = false,
  modifiers = {}
}: {
  enabledAge?: boolean;
  modifiers?: { [key: string]: string } | undefined,
}): string => {
  const gender = trainingMeta?.gender === 'Male' ? 'man' : 'woman';
  const genderDescriptor = getGenderDescriptor(trainingMeta?.gender || '');
  const age = enabledAge ? getAgeDescription(trainingMeta?.age || '') : '';

  /**
   * Generate the ethnicity description, if available. Otherwise, use an empty string.
   */
  const ethnicity = parseEthnicity(trainingMeta?.ethnicity || '', !trainingMeta?.ethnicity).trim();

  /**
   * Replace the {body type} placeholder with the actual body type, if available. Otherwise, replace it with an empty string.
   */
  const bodyType = trainingMeta?.bodyType ? parseBodyType(trainingMeta.bodyType, trainingMeta.gender as 'Male' | 'Female').trim() : '';

  /**
   * Replace the {hair length} placeholder with the actual hair length, if available. Otherwise, replace it with an empty string.
   */
  const hairLength = getHairLengthDescription(trainingMeta?.hairLength);

  /**
   * Replace the {glasses} placeholder, if the model has the glasses attribute set to true. Otherwise, replace it with an empty string.
   */
  const glasses = getGlassesDescription(trainingMeta?.glasses || false, modifiers.glasses);

  //  return `${genderDescriptor} ${age} ${ethnicity} ${gender} TOK with a ${bodyType} figure ${hairLength} ${glasses}`.trim();
   return `${genderDescriptor} ${age} ${ethnicity} TOK ${gender} model ${hairLength} ${glasses}`.trim();
};