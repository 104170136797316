
import { Theme } from '@acme/db';
import { getPromptFromTheme } from './getPromptFromTheme';
import { processPromptTriggers } from './processPromptTriggers';
import { TrainingMeta } from './types/trainingMeta';

interface GetPromptWithTriggersProps {
  theme: Theme;
  trainingMeta: TrainingMeta;
  count: number;
  modifiers?: { [key: string]: string } | undefined;
  enabledAge?: boolean;
}

export const getPromptWithTriggers = async ({ theme, trainingMeta, count, modifiers, enabledAge  }: GetPromptWithTriggersProps) => {
  let { prompt: initialPrompt, negativePrompt: initialNegativePrompt } = getPromptFromTheme(theme, '');

  let { prompts, negativePrompts } = await processPromptTriggers(
    initialPrompt,
    initialNegativePrompt,
    trainingMeta,
    count,
    modifiers,
    enabledAge
  );

  return { prompts, negativePrompts };
};
