import { prisma } from '@acme/db/client'
import { Gender_Association, tag_category_type, Modifier, Prisma } from '@acme/db';
import { TrainingMeta } from './types/trainingMeta';
import { removeExtraSpaces } from './removeExtraSpaces';
import { getGenderAssociation } from './getGenderAssociation';
import { getTrigger } from './trigger/getTrigger';
import { logger } from './logger';

interface ProcessedPrompts {
  prompts: string[];
  negativePrompts: string[];
}

const processPromptTriggers = async (
  prompt: string,
  negativePrompt: string,
  trainingMeta: TrainingMeta,
  count: number,
  modifiers?: { [key: string]: string } | undefined,
  enabledAge?: boolean
): Promise<ProcessedPrompts> => {
  logger.debug('Starting processPromptTriggers', { prompt, negativePrompt, trainingMeta, count, modifiers, enabledAge });

  const trigger = removeExtraSpaces(getTrigger(trainingMeta, { enabledAge: enabledAge, modifiers: modifiers }));
  logger.debug('Generated trigger', { trigger });

  prompt = replacePromptPlaceholders(prompt, trigger, trainingMeta);
  negativePrompt = replacePromptPlaceholders(negativePrompt, trigger, trainingMeta);
  logger.debug('Replaced prompt placeholders', { prompt, negativePrompt });

  const tags = prompt.match(/{.*?}/g) ?? [];
  const tagNames = tags.map(tag => tag.slice(1, -1));
  logger.debug('Extracted tag names', { tagNames });

  const categories = await fetchModifiers(tagNames, getGenderAssociation(trainingMeta.gender)!);

  return generatePrompts(prompt, negativePrompt, count, categories, modifiers);
};

const replacePromptPlaceholders = (text: string, trigger: string, trainingMeta: TrainingMeta): string => {
  // Replace {trigger} with the actual trigger
  text = text.replaceAll('{trigger}', trigger);


  // Replace {eyeColor} with the eye color if available
  if (trainingMeta?.eyeColor) {
    text = text.replaceAll('{eyeColor}', trainingMeta.eyeColor);
  }


  // Remove any remaining placeholders
  text = text
    .replaceAll('{eyeColor}', '')

  return text;
};

const fetchModifiers = async (tagNamesOrCategories: string[], gender: string) => {
  logger.debug('Fetching modifiers', { tagNamesOrCategories, gender });

  const genderAssociation = getGenderAssociation(gender);
  
  const validCategories = tagNamesOrCategories.filter(item => 
    Object.values(tag_category_type).includes(item as tag_category_type)
  ) as tag_category_type[];
  logger.debug('Valid categories', { validCategories });

  const nonCategoryTags = tagNamesOrCategories.filter(item => 
    !Object.values(tag_category_type).includes(item as tag_category_type)
  );
  logger.debug('Non-category tags', { nonCategoryTags });

  const MAX_MODIFIERS_PER_TAG = 40; 


  let categoryModifiers: Modifier[] = [];
  const genderAssociations = genderAssociation
  ? [getGenderAssociation(genderAssociation), 'gender-neutral']
  : ['gender-neutral'];

  if (validCategories.length > 0) {
    categoryModifiers = await prisma.$queryRaw<Modifier[]>`
      WITH ranked_modifiers AS (
        SELECT *,
               ROW_NUMBER() OVER (PARTITION BY category, tag_name ORDER BY RANDOM()) as rn
        FROM "prompttemplates"."modifiers"
        WHERE category::text IN (${Prisma.join(validCategories)})
          AND gender_association::text IN (${Prisma.join(genderAssociations)})
      )
      SELECT * FROM ranked_modifiers
      WHERE rn <= ${MAX_MODIFIERS_PER_TAG}
    `;
  }


  const tagModifiers = await prisma.modifier.findMany({
    where: {
      tag_name: { in: nonCategoryTags },
      gender_association: {
        in: genderAssociation
          ? [genderAssociation, Gender_Association.gender_neutral]
          : [Gender_Association.gender_neutral]
      }
    }
  });

  logger.debug('Fetched modifiers', { categoryModifiersCount: categoryModifiers.length, tagModifiersCount: tagModifiers.length });
  return {categoryModifiers,tagModifiers};
};



const generatePrompts = async (
  prompt: string,
  negativePrompt: string,
  count: number,
  categories: Awaited<ReturnType<typeof fetchModifiers>>,
  modifiers?: { [key: string]: string } | undefined
): Promise<ProcessedPrompts> => {

  const prompts: string[] = [];
  const negativePrompts: string[] = [];

  for (let i = 0; i < count; i++) {
    let promptCopy = prompt;
    logger.debug(`Generating prompt ${i + 1}`, { originalPrompt: promptCopy });

  
    // Process all modifiers ( values selected by the user )
    if (modifiers) {
      for (const [key, value] of Object.entries(modifiers)) {
        console.log({key})
        if (value !== 'Random') {
          logger.debug(`Replacing {${key}} with ${value}`);
          promptCopy = promptCopy.replaceAll(`{${key}}`, value);

          // If this modifier is not in the initialk promp, it may be a category, so we check that case and if that is strue, we replaced it
          if (!categories.tagModifiers.find(modifier => modifier.tag_name === key)) {
            const category = categories.categoryModifiers.find(modifier => modifier.tag_name === key);
            if (category) {
              logger.debug(`Found category ${category.category} for tag ${key}, replacing {${category.category}} with ${value}`);
              promptCopy = promptCopy.replaceAll(`{${category.category}}`, value);
            }
          }
        }
      }
    }

    const categoriesAvailable = categories.categoryModifiers.reduce((acc, modifier) => {
      if (modifier.category && !acc.includes(modifier.category)) {
        acc.push(modifier.category);
      }
      return acc;
    }, [] as tag_category_type[]);

    const tagsAvailable = categories.tagModifiers.reduce((acc, modifier) => {
      if (modifier.tag_name && !acc.includes(modifier.tag_name)) {
        acc.push(modifier.tag_name);
      }
      return acc;
    }, [] as string[]);
    logger.debug('Categories available', { categoriesAvailable })
    logger.debug('Tags available', { tagsAvailable })
    // First, we replace all the categories available. For example, if the prompt is {category1} {category2} {tag1} {tag2},
    // and the categories available are category1 and category2, we replace all {category1} and {category2} with a random value from the available categories.
    for (const category of categoriesAvailable) {
      const categoryModifiers = categories.categoryModifiers.filter(modifier => modifier.category === category);
      const randomCategoryModifier = categoryModifiers[Math.floor(Math.random() * categoryModifiers.length)];
      promptCopy = promptCopy.replaceAll(`{${category}}`, randomCategoryModifier?.value ?? '');
    }

    // Then, we replace all the tags available. For example, if the prompt is {tag1} {tag2} and the tags available are tag1 and tag2,
    // we replace all {tag1} and {tag2} with a random value from the available tags.
    for (const tag of tagsAvailable) {
      const tagModifiers = categories.tagModifiers.filter(modifier => modifier.tag_name === tag);
      const randomTagModifier = tagModifiers[Math.floor(Math.random() * tagModifiers.length)];
      promptCopy = promptCopy.replaceAll(`{${tag}}`, randomTagModifier?.value ?? '');
    }

    logger.debug(`Final prompt ${i + 1}`, { finalPrompt: promptCopy });
    prompts.push(promptCopy);
    negativePrompts.push(negativePrompt);
  }

  return { prompts, negativePrompts };
};

export { processPromptTriggers };
