import { parsePicstudioNodes } from './comfy/parsePicstudioNodes';
import { getAvailableWorkflowFromName, WorkflowsName, getAllWorkflows } from './comfy/workflows';

type BaseInput = {
  seed?: number;
  width: number;
  height: number;
  prompt: string;
  scheduler: string;
  pose_image: string;
  num_outputs: number;
  guidance_scale: number;
  negative_prompt: string;
  num_inference_steps: number;
  disable_safety_check: boolean;
  strength?: number;
  ckpt_name?: string;
};

export type Img2ImgInput = BaseInput & {
  image: string;
};

export type FaceSwapInput = BaseInput & {
  training_image_url: string;
  image: string;
};

export type ExtendedInput = BaseInput & {
  image?: string;
  training_image_url?: string;
  picstudio_model_id?: string;
  lora_name?: string;
  lora_strength?: number;
};

export const constructWorkflowV2 = (workflowName: WorkflowsName, input: ExtendedInput): object | null => {
  if (!input) return null;
  const allWorkflows = getAllWorkflows();
  if (allWorkflows.find(workflow => workflow.value === workflowName)) {
    const workflow = allWorkflows.find(workflow => workflow.value === workflowName)?.value;
    return parsePicstudioNodes(input, getAvailableWorkflowFromName(workflow as WorkflowsName)!);
  }

  return null;
};
