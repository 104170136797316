import { useSession } from '@hooks/index';
import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { useEffect } from 'react';
import { runStatsigSessionReplay } from '@statsig/session-replay';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { useTrackEvent } from './trackEvent';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';

function storeCampaignParamsInLocalStorage(params: Record<string, string>) {
  try {
    if (Object.keys(params).length > 0) {
      localStorage.setItem('initial_campaign_params', JSON.stringify(params));
    }
  } catch (error) {
    console.error('Error storing campaign params in localStorage:', error);
  }
}

export function getStoredCampaignParams(): Record<string, string> {
  try {
    const storedParams = localStorage.getItem('initial_campaign_params');
    return storedParams ? JSON.parse(storedParams) : {};
  } catch (error) {
    console.error('Error reading campaign params from localStorage:', error);
    return {};
  }
}

function shouldIgnoreReferrer(referrer: string): boolean {
  try {
    const url = new URL(referrer);
    const ignoredDomains = [
      'accounts.google.com',
      'accounts.google.', // catches country-specific domains
      'checkout.stripe.com'
    ];

    return ignoredDomains.some(domain => url.hostname.includes(domain));
  } catch (error) {
    console.error('Error checking referrer:', error);
    return true;
  }
}

function hasSessionCampaignParams(): boolean {
  try {
    return sessionStorage.getItem('campaign_params_set') === 'true';
  } catch (error) {
    console.error('Error checking session campaign params:', error);
    return false;
  }
}

function markCampaignParamsSet() {
  try {
    sessionStorage.setItem('campaign_params_set', 'true');
  } catch (error) {
    console.error('Error marking campaign params as set:', error);
  }
}

function getCampaignParams(
  query?: Record<string, string | string[] | undefined>,
  addPrefix: boolean = true
): Record<string, string> {
  if (typeof window !== 'undefined' && hasSessionCampaignParams()) {
    return {};
  }

  if (!query || typeof query !== 'object') return {};

  const campaignParams: Record<string, string> = {};
  const commonParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'gclid',
    'gclsrc',
    'dclid',
    'fbclid',
    'msclkid',
    'mc_eid',
    'mc_cid',
    'twclid',
    'li_fat_id',
    'igshid',
    'utm_id',
    'ttc',
    'ttclid',
    'ttc_id'
  ];

  try {
    // Process common campaign parameters
    commonParams.forEach(param => {
      const value = query[param];
      if (value) {
        const paramKey = addPrefix ? `initial_${param}` : param;
        if (typeof value === 'string') {
          campaignParams[paramKey] = value;
        } else if (Array.isArray(value) && typeof value[0] === 'string') {
          campaignParams[paramKey] = value[0];
        }
      }
    });

    // Add referrer information if available and not already stored
    if (typeof window !== 'undefined') {
      const storedParams = getStoredCampaignParams();
      const hasStoredReferrer = storedParams['initial_referrer'];

      // Only process referrer if we don't have one stored
      if (!hasStoredReferrer) {
        const referrer = document.referrer;
        if (referrer && !shouldIgnoreReferrer(referrer)) {
          try {
            const refUrl = new URL(referrer);
            // Don't track internal referrers
            if (!refUrl.hostname.includes(window.location.hostname)) {
              const paramKey = addPrefix ? 'initial_referrer' : 'referrer';
              campaignParams[paramKey] = referrer;

              // Add search engine and query information
              const searchEngine = getSearchEngine(refUrl);
              if (searchEngine) {
                const seKey = addPrefix ? 'initial_search_engine' : 'search_engine';
                campaignParams[seKey] = searchEngine;

                const searchQuery = refUrl.searchParams.get(searchEngine === 'yahoo' ? 'p' : 'q');
                if (searchQuery) {
                  const sqKey = addPrefix ? 'initial_search_query' : 'search_query';
                  campaignParams[sqKey] = searchQuery;
                }
              }
            }
          } catch (error) {
            console.error('Error processing referrer URL:', error);
          }
        }
      }
    }

    if (Object.keys(campaignParams).length > 0) {
      markCampaignParamsSet();
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('Extracted campaign params:', campaignParams);
      console.log('Original query:', query);
    }
  } catch (error) {
    console.error('Error processing campaign params:', error);
    return {};
  }

  return campaignParams;
}

function getSearchEngine(refUrl: URL): string {
  const host = refUrl.hostname;
  const engines = ['google', 'bing', 'yahoo', 'duckduckgo', 'baidu'];
  const engine = engines.find(e => host.includes(`${e}.`));
  return engine || '';
}

function AuthSuccessListener({ children }: { children: React.ReactNode }) {
  const { user } = useSession();
  const { query } = useRouter();
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    try {
      if (query?.authSuccess && user?.id) {
        trackEvent('login', {
          value: user.id
        });
      }
    } catch (error) {
      console.error('Error in AuthSuccessListener:', error);
    }
  }, [query.authSuccess]);

  return children;
}

export const AnalyticsStatsigProvider = ({ ...props }) => {
  const { user, isLoggedIn, isLoading } = useSession();
  const router = useRouter();
  const clientKey = process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY;
  const [cookies, setCookie] = useCookies(['stableId']); // Add cookies hook

  const { client } = useClientAsyncInit(clientKey || '', {
    userID: user?.id || '',
    email: user?.email || '',
    custom: {
      username: user?.username || '',
      isAdmin: !!user?.isAdmin
    }
  });

  // Initialize Statsig features once we know the session state
  useEffect(() => {
    if (!isLoading && client) {
      console.log('Initializing Statsig auto-capture. Session loaded, user:', user?.id || 'anonymous');
      runStatsigAutoCapture(client);
      runStatsigSessionReplay(client);
    }
  }, [isLoading, client]);

  // Handle UTM parameters once router is ready
  useEffect(() => {
    try {
      if (!router.isReady || !client) return;

      const context = client.getContext();
      const contextUser = context?.user;

      if (!contextUser?.custom || !Object.keys(contextUser.custom).some(key => key.startsWith('initial_'))) {
        const campaignParams = getCampaignParams(router.query);

        if (Object.keys(campaignParams).length > 0) {
          console.log('Updating user with campaign params:', campaignParams);
          storeCampaignParamsInLocalStorage(getCampaignParams(router.query, false));

          client.updateUserSync({
            userID: user?.id || '',
            email: user?.email || '',
            custom: {
              username: user?.username || '',
              isAdmin: !!user?.isAdmin,
              ...contextUser?.custom,
              ...campaignParams
            }
          });
        }
      }
    } catch (error) {
      console.error('Error processing campaign params after router ready:', error);
    }
  }, [router.isReady, client]);

  // Handle user updates
  useEffect(() => {
    try {
      if (isLoggedIn && user?.id && client) {
        const context = client.getContext();
        const contextUser = context?.user;

        if (!contextUser?.email) {
          let existingCampaignParams = {};

          if (contextUser?.custom && typeof contextUser.custom === 'object') {
            existingCampaignParams = Object.entries(contextUser.custom)
              .filter(([key]) => key && typeof key === 'string' && key.startsWith('initial_'))
              .reduce<Record<string, string>>((acc, [key, value]) => {
                if (typeof value === 'string') {
                  acc[key] = value;
                }
                return acc;
              }, {});
          }

          client.updateUserSync({
            userID: user.id,
            email: user.email || '',
            custom: {
              isAdmin: !!user.isAdmin,
              ...existingCampaignParams,
              ...(Object.keys(existingCampaignParams).length === 0 && router.isReady
                ? getCampaignParams(router.query)
                : {})
            }
          });
        }
      }
    } catch (error) {
      console.error('Error updating user context:', error);
    }
  }, [isLoggedIn, user, client]);

  useEffect(() => {
    try {
      if (!client) return;

      const context = client.getContext();
      const stableId = context?.stableID;

      if (stableId && !cookies.stableId) {
        const domain =
          window.location.hostname === 'localhost'
            ? 'localhost'
            : `.${window.location.hostname.split('.').slice(-2).join('.')}`;

        setCookie('stableId', stableId, {
          path: '/',
          expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
          domain: domain,
          secure: process.env.NODE_ENV === 'production',
          sameSite: 'lax'
        });
      }
    } catch (error) {
      console.error('Error handling stableId:', error);
    }
  }, [client, cookies.stableId, setCookie]);

  return (
    <StatsigProvider
      client={client}
      options={{
        networkConfig: {
          preventAllNetworkTraffic: typeof window === 'undefined'
        },
        disableStorage: typeof window === 'undefined',
        environment: {
          tier: process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? 'production' : 'development'
        }
      }}
    >
      <AuthSuccessListener>{props.children}</AuthSuccessListener>
    </StatsigProvider>
  );
};
